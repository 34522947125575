import { ChainId } from "@photonswap/sdk";
import MULTICALL_ABI from "./abi.json";

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.CRONOSTEST]: "0x9d5A7f8c36F26B2D0f52DB553a1f1236f071200f",
  [ChainId.CRONOSMAINNET]: "0x361Ab0c6cB488cCF262f46662aba77D621FcE626",
  [ChainId.CASSINI]: "0x1b81c335931f2D783dE929E20A8c80431DAdA5fC",
  [ChainId.BSC_TEST_NET]: "0x1b81c335931f2D783dE929E20A8c80431DAdA5fC",
  [ChainId.MUMBAI_TESTNET]: "0x1b81c335931f2D783dE929E20A8c80431DAdA5fC",
  [ChainId.EVMOS_TESTNET]: "0xB7D0AE4E4d1343bc5e3fE940DA06D84350cdbCE8",
  [ChainId.EVMOS_MAINNET]: "0x8a340F39A468C2FcBFFf2122446a9A0745A313Ad",
  [ChainId.KAVA_TESTNET]: "0x8a340F39A468C2FcBFFf2122446a9A0745A313Ad",
  [ChainId.KAVA_MAINNET]: "0x7ED7bBd8C454a1B0D9EdD939c45a81A03c20131C",
  [ChainId.SHARDEUM_LIBERTY_TESTNET]:"0xA8eFf8BB28c6193CBE8BcFb8276e9b1dD3380B13",
  [ChainId.HAQQ_TESTNET]: "0x8a340F39A468C2FcBFFf2122446a9A0745A313Ad",
  [ChainId.REBUS_TESTNET]: "0xf88F1ecFa8510c9E61655Df5604fAFDC43F4b8DD",
  [ChainId.MINTME_MAINNET]: "0xd35a4a44c6DbD682dC1AC8A9Fb92EDFC0488dE78",
  [ChainId.POLYGON_ZK_TESTNET]: "0xA8eFf8BB28c6193CBE8BcFb8276e9b1dD3380B13",
  [ChainId.MANTLE_TESTNET]: "0x8a340F39A468C2FcBFFf2122446a9A0745A313Ad",
  [ChainId.PEPE_CHAIN]: "0x8a340F39A468C2FcBFFf2122446a9A0745A313Ad",
  [ChainId.SEPOLIA]: "0x8a340F39A468C2FcBFFf2122446a9A0745A313Ad",
};

export { MULTICALL_ABI, MULTICALL_NETWORKS };
