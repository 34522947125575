import { Interface } from '@ethersproject/abi'
import { ChainId } from '@photonswap/sdk'
import V1_EXCHANGE_ABI from './v1_exchange.json'
import V1_FACTORY_ABI from './v1_factory.json'

const V1_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.CRONOSMAINNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.CRONOSTEST]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.CASSINI]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.BSC_TEST_NET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.MUMBAI_TESTNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.EVMOS_TESTNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.EVMOS_MAINNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.KAVA_TESTNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.KAVA_MAINNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.SHARDEUM_LIBERTY_TESTNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.HAQQ_TESTNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.REBUS_TESTNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.MINTME_MAINNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.POLYGON_ZK_TESTNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.MANTLE_TESTNET]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.SEPOLIA]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
  [ChainId.PEPE_CHAIN]: '0xEc80b270D493Ba70B6359b9CE041B0BFBA316D61',
}

const V1_FACTORY_INTERFACE = new Interface(V1_FACTORY_ABI)
const V1_EXCHANGE_INTERFACE = new Interface(V1_EXCHANGE_ABI)

export { V1_FACTORY_ADDRESSES, V1_FACTORY_INTERFACE, V1_FACTORY_ABI, V1_EXCHANGE_INTERFACE, V1_EXCHANGE_ABI }
