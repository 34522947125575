import React from "react";
import styled from "styled-components";

const ToggleElement = styled.span<{ isActive?: boolean; isOnSwitch?: boolean }>`
  padding: 0.25rem 0.5rem;
  border-radius: 14px;

  padding: 0.35rem 0.6rem;
  border-radius: 12px;
  background: ${({ theme, isActive, isOnSwitch }) =>
    isActive ? (isOnSwitch ? theme.primary1 : theme.text3) : "none"};
  color: ${({ theme, isActive, isOnSwitch }) =>
    isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text3};
  font-size: 1rem;
  font-weight: ${({ isOnSwitch }) => (isOnSwitch ? "500" : "400")};
  :hover {
    user-select: ${({ isOnSwitch }) => (isOnSwitch ? "none" : "initial")};
    background: ${({ theme, isActive, isOnSwitch }) =>
      isActive ? (isOnSwitch ? theme.primary1 : theme.text3) : "none"};
    color: ${({ theme, isActive, isOnSwitch }) =>
      isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text3};
  }
`;

const StyledToggle = styled.button<{
  isActive?: boolean;
  activeElement?: boolean;
}>`
  border-radius: 12px;
  border: none;
  /* border: 1px solid ${({ theme, isActive }) =>
    isActive ? theme.primary5 : theme.text4}; */
  background: ${({ theme }) => theme.bg3};
  display: flex;
  width: fit-content;
  cursor: pointer;
  outline: none;
  padding: 0;
  /* background-color: transparent; */
`;

export interface ToggleProps {
  id?: string;
  name1?: string;
  name2?: string;
  isActive: boolean;
  toggle: () => void;
}

export default function Toggle({
  id,
  name1,
  name2,
  isActive,
  toggle,
}: ToggleProps) {
  return (
    <StyledToggle id={id} isActive={isActive} onClick={toggle}>
      <ToggleElement isActive={isActive} isOnSwitch={true}>
        {name1 ?? "On"}
      </ToggleElement>
      <ToggleElement isActive={!isActive} isOnSwitch={false}>
        {name2 ?? "Off"}
      </ToggleElement>
    </StyledToggle>
  );
}
