import styled from "styled-components";
import { AutoColumn } from "../Column";

import uImage from "../../assets/images/trustWallet.png";
import xlUnicorn from "../../assets/images/trustWallet.png";
import noise from "../../assets/images/trustWallet.png";

export const TextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: fit-content;
  justify-self: flex-end;
`;

export const DataCard = styled(AutoColumn)<{ disabled?: boolean }>`
  background: ${({ theme }) => ` ${theme.darkTransparent} `};
  border: 2px solid ${({ theme }) => theme.border1};
  border-radius: 12px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const CardBGImage = styled.span<{ desaturate?: boolean }>`
  background: url(${uImage});
  width: 1000px;
  height: 600px;
  position: absolute;
  border-radius: 12px;
  opacity: 0.1;
  top: -80px;
  left: -180px;
  transform: rotate(-20deg);
  user-select: none;

  ${({ desaturate }) => desaturate && `filter: saturate(0)`}
`;

export const CardBGImageSmaller = styled.span<{ desaturate?: boolean }>`
  background: url(${xlUnicorn});
  width: 1200px;
  height: 1200px;
  position: absolute;
  border-radius: 12px;
  top: -300px;
  left: -300px;
  opacity: 0.4;
  user-select: none;

  ${({ desaturate }) => desaturate && `filter: saturate(0)`}
`;

export const CardNoise = styled.span`
  background: url(${noise});
  background-size: cover;
  mix-blend-mode: overlay;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
`;

export const CardSection = styled(AutoColumn)<{ disabled?: boolean }>`
  padding: 1rem;
  z-index: 1;
  opacity: ${({ disabled }) => disabled && "0.4"};
`;

export const Break = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
`;
